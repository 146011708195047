define("discourse/plugins/discourse-topic-tag-scheduler/discourse/connectors/after-title-and-category/schedule-topic-button", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse-i18n", "discourse/plugins/discourse-topic-tag-scheduler/discourse/components/schedule-topic-modal", "discourse/plugins/discourse-topic-tag-scheduler/discourse/lib/constants", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _discourseI18n, _scheduleTopicModal, _constants, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ScheduleTopicButton extends _component.default {
    static shouldRender(_, _ref) {
      let {
        currentUser
      } = _ref;
      return currentUser?.staff;
    }
    static #_ = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    constructor() {
      super(...arguments);
      const startsAt = this.composerModel.get(`topic.${_constants.STARTS_AT_FIELD}`);
      if (startsAt) {
        const endsAt = this.composerModel.get(`topic.${_constants.ENDS_AT_FIELD}`);
        this.composerModel.setProperties({
          [_constants.STARTS_AT_FIELD]: startsAt,
          [_constants.ENDS_AT_FIELD]: endsAt
        });
      }
    }
    updateDateRange(startsAt, endsAt) {
      this.composerModel.setProperties({
        [_constants.STARTS_AT_FIELD]: startsAt?.toISOString() ?? "",
        [_constants.ENDS_AT_FIELD]: endsAt?.toISOString() ?? ""
      });
      if (startsAt === null && endsAt === null) {
        const tags = this.composerModel.get("tags") || [];
        const updatedTags = tags.filter(tag => tag !== this.siteSettings.topic_tag_scheduler_tag);
        this.composerModel.set("tags", updatedTags);
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "updateDateRange", [_object.action]))();
    openScheduleModal() {
      this.modal.show(_scheduleTopicModal.default, {
        model: {
          startsAt: this.composerModel.get(_constants.STARTS_AT_FIELD),
          endsAt: this.composerModel.get(_constants.ENDS_AT_FIELD),
          updateDateRange: this.updateDateRange
        }
      });
    }
    static #_4 = (() => dt7948.n(this.prototype, "openScheduleModal", [_object.action]))();
    get label() {
      const startsAt = this.composerModel.get(_constants.STARTS_AT_FIELD);
      const endsAt = this.composerModel.get(_constants.ENDS_AT_FIELD);
      if (!startsAt) {
        return (0, _discourseI18n.i18n)("discourse_topic_tag_scheduler.schedule");
      }
      const formattedStartsAt = new Date(startsAt).toLocaleDateString(_discourseI18n.default.currentBcp47Locale, {
        day: "numeric",
        month: "short"
      });
      const formattedEndsAt = new Date(endsAt).toLocaleDateString(_discourseI18n.default.currentBcp47Locale, {
        day: "numeric",
        month: "short"
      });
      return `${formattedStartsAt} - ${formattedEndsAt}`;
    }
    get composerModel() {
      return this.args.outletArgs.model;
    }
    get isScheduled() {
      return !!this.composerModel.get(_constants.STARTS_AT_FIELD);
    }
    static #_5 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DButton
          class="schedule-topic-button"
          @action={{this.openScheduleModal}}
          @translatedLabel={{this.label}}
          @icon={{if this.isScheduled "far-calendar-check" "far-calendar-plus"}}
        />
      
    */
    {
      "id": "rmLKzvS5",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"schedule-topic-button\"]],[[\"@action\",\"@translatedLabel\",\"@icon\"],[[30,0,[\"openScheduleModal\"]],[30,0,[\"label\"]],[52,[30,0,[\"isScheduled\"]],\"far-calendar-check\",\"far-calendar-plus\"]]],null],[1,\"\\n  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-topic-tag-scheduler/discourse/connectors/after-title-and-category/schedule-topic-button.js",
      "scope": () => [_dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ScheduleTopicButton;
});